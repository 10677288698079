<template>
  <div class="flex-column" v-loading="loading">
    <sticky :className="'sub-navbar'">
      <div class="filter-container">
        <!-- <el-input @keyup.enter.native="handleFilter" size="mini" class="filter-item top_search_css" v-model.trim="listQuery.key" :placeholder="'用户名称/表名称/表编号'" clearable style="width: 200px !important"> </el-input> -->
        <el-select  clearable v-model="fatherIds" style="width:500px;" filterable multiple collapse-tags placeholder="请选择总表">
          <el-option v-for="(j, jdx) in allTotalMeter" :key="jdx" :value="j.id" :label="j.surfaceNum + j.surfaceName"></el-option>
        </el-select>

        <el-switch v-model="chooseAreaOrUser" active-text="按户表选择" inactive-text="按区域选择" style="margin:0 10px;"> </el-switch>

        <!-- 树形下拉选择 - 区域 -->
        <PropertyTreeSelect v-if="!chooseAreaOrUser" :options="areaOptions" :defaultProps="defaultProps" :defaultValue="defaultValue"  @changeSelectDataList="changeSelectDataList" :selectWidth="'300'" style="margin:0 10px;" :placeholder="'请选择区域'"></PropertyTreeSelect>

        <!-- 树形下拉选择 - 子表 -->
        <el-select v-else v-model="searchMeters" multiple remote filterable reserve-keyword :remote-method="remoteMethod" collapse-tags clearable placeholder="请选择户表" :loading="loading" style="margin-right:10px;width:300px;">
          <el-option v-for="item in memterOptions" :key="item.id" :label="item.surfaceName" :value="item.id">
            <span>表名称：{{ item.surfaceName }}</span>
            <span>表编号：{{ item.surfaceNum }}</span>
          </el-option>
        </el-select>

        <!--  -->
        <el-button class="filter-item" size="mini" type="primary" plain @click="chooseDate('today')">今天</el-button>
        <el-button class="filter-item" size="mini" type="primary" plain @click="chooseDate('yesterday')">昨天</el-button>
        <el-button class="filter-item" size="mini" type="primary" plain @click="chooseDate('sevenTime')">最近七天</el-button>
        <el-date-picker v-model="listQuery.timer" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" style="margin:0 10px;width: 280px !importantheight: 33px;vertical-align: middle;"> </el-date-picker>
        <el-button class="filter-item" size="mini" type="primary" v-waves icon="el-icon-search" @click='handleFilter'>搜索</el-button>
      </div>
    </sticky>
    <div class="app-container flex-item">
      <el-row :gutter="8" style="margin-bottom:10px">
        <el-col :span="8" class="total" >
          <el-card class="Dtop_l_num">
            <p class="topNum_title">总表流量合计</p>
            <animate-number from="0" :to="totalFatherNum" :key="totalFatherNum" duration="2000" :formatter="formatter"></animate-number>
          </el-card>
        </el-col>
        <el-col :span="8" class="total" >
          <el-card class="Dtop_l_num">
            <p class="topNum_title">流量差值</p>
            <animate-number from="0" :to="differenceNum" :key="differenceNum" duration="2000" style="color:red" :formatter="formatter"></animate-number>
          </el-card>
        </el-col>
        <el-col :span="8" class="total" >
          <el-card class="Dtop_l_num">
           <p class="topNum_title">户表流量合计</p>
            <animate-number from="0" :to="totalChildNum" :key="totalChildNum" duration="2000" :formatter="formatter"></animate-number>
          </el-card>
        </el-col>
      </el-row>

      <el-row  :gutter="8" >
        <el-col :span="12" class="zzb" id="table_box_height">
          <div class="bg-white">
            <basic-table :tableHeight="tableHeight" :tableHeaderList="tableHeaderList" :tableData="totalMemtertableData" :hasPagination='false'></basic-table >
          </div>
        </el-col>
        <el-col :span="12" class="zzb">
          <div class="bg-white">
            <basic-table :tableHeight="tableHeight" :tableHeaderList="tableHeaderList" :tableData="totalChildMemtertableData" :hasPagination='false'></basic-table >
          </div>
        </el-col>
      </el-row>
    </div>

  </div>

</template>

<script>
  import * as basicDataApi from '@/api/basicDataApi/basicDataApi'//基础资料接口
  import basicTable from './table'//表格组件
  import PropertyTreeSelect from './selectTree'

  
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import waves from '@/directive/waves' // 水波纹指令
  import Sticky from '@/components/Sticky'
  import elDragDialog from '@/directive/el-dragDialog'
  export default {
    name: 'hdybqygl',
    components: {
      Sticky,
      basicTable,
      PropertyTreeSelect
    },
    directives: {
      waves,
      elDragDialog
    },
    data() {
      return {
        // 选项列表
        memterOptions: [],
        searchMeters: [], // 选中的值
        loading: false,



        fatherIds:[],//总表选择id集合
        allTotalMeter:[],//所有总表数据
        totalFatherNum:0,//总表统计
        totalChildNum:0,//子表统计
        differenceNum:0,//流量差值
        listQuery: { // 查询条件
          page: 1,
          limit: 50,
          timer:[],
          treeIds:[],
          key: '',
          beginTime:'',
          endTime:'',
        },
        tableHeight:500,//表格高度
        tableHeaderList:[],// 表列定义
        totalMemtertableData:[],//总表数据
        totalChildMemtertableData:[],//分表数据
        
        CheckedKey:[],//选中数据的id集合
        tableChooseRow:{},//table选中数据
        
        

        // 数据格式化
        defaultProps: {
          children: 'children',
          label: 'label',
          value: 'childId'
        },
        defaultValue: [],
        chooseAreaIds:[],//选择区域ids
        chooseAreaOrUser:false,//false区域，true用户
      }
    },
    watch: {
      
    },
    computed: {
      orgsTree() {
        return this.$store.state.AllArea;
      },
      areaOptions() {
        return this.$store.state.AllArea;
      },
    },
    filters: {
      
    },
    created() {
      
    },
    mounted() {
      // 计算table高度
      this.$nextTick(()=>{
        let ele=document.getElementById("table_box_height");
        this.tableHeight=ele.offsetHeight + 130
        window.addEventListener('resize', () => { 
          let ele=document.getElementById("table_box_height");
          this.tableHeight=ele.offsetHeight + 130
          this.$forceUpdate()
        });
      })

      var today = new Date();
      var yesterday = this.$basicFun.getDay(-1);
      this.listQuery.timer = [yesterday,today]
      // this.getList()
      this.getAllMainMeter()//获取所有大表数据

    },
    methods: {
      formatter: function (num) {
        return num.toFixed(2)
      },
      //下拉树形选择数据
      changeSelectDataList (val) {
        this.chooseAreaIds = val.map(v => v.childId)
      },
      //获取所有大表数据
      getAllMainMeter(){
        basicDataApi.getAllMainMeter().then(response => {
          if(response.code == 200){
            this.allTotalMeter = response.result
          }else{
            this.allTotalMeter = []
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
        })
      },
      //远程搜索小表
      remoteMethod(query){
        if(query !== ''){
          this.loading = true;
          var data={
            areaIds:[],
            keyword:query
          }
          basicDataApi.getAllChildMeter(data).then(response => {
            if(response.code == 200){
              this.memterOptions = response.result
              this.loading = false;
            }else{
              this.memterOptions = []
              this.loading = false;
              this.$notify({
                message: response.message,
                type: 'warning',
                duration: 2000
              });
            }
          })
          // setTimeout(() => {
          //   this.loading = false;
          //   this.memterOptions = this.list.filter(item => {
          //     return item.label.toLowerCase()
          //       .indexOf(query.toLowerCase()) > -1;
          //   });
          // }, 200);
        }else{
          this.memterOptions = []
        }
        return
        basicDataApi.getAllChildMeter().then(response => {
          if(response.code == 200){
            this.allTotalMeter = response.result
          }else{
            this.allTotalMeter = []
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
        })
      },

      
      //选择时间
      chooseDate(time){
        var today = new Date();
        var yesterday = this.$basicFun.getDay(-1);
        var beforeYesterday = this.$basicFun.getDay(-2);
        var sevenTime = this.$basicFun.getDay(-7);
        if(time == 'today'){
          this.listQuery.timer = [yesterday,today]
        }
        if(time == 'yesterday'){
          this.listQuery.timer = [beforeYesterday,yesterday]
        }
        if(time == 'sevenTime'){
          this.listQuery.timer = [sevenTime,today]
        }
        this.handleFilter()
      },
      // 按钮点击
      onBtnClicked: function(domId) {
        console.log('you click:' + domId)
        switch (domId) {
          case 'btnDel'://删除
            // this.handleDelete(this.tableChooseRow)
            break
          case 'btnAdd'://添加
            
            break
          default:
            break
        }
      },
      // 获取数据
      getList() {
        this.totalFatherNum = this.totalChildNum = this.differenceNum = 0//重置为0，动画过度
        if(this.listQuery.timer){
          this.listQuery.beginTime = this.$basicFun.dataFormat(this.listQuery.timer[0],'yyyy-MM-dd');
          this.listQuery.endTime = this.$basicFun.dataFormat(this.listQuery.timer[1],'yyyy-MM-dd');
        }else{
          this.listQuery.beginTime = this.listQuery.endTime = ''
        }
        var data = {}
        if(this.chooseAreaOrUser){//选择表
          data = {
            beginTime:this.listQuery.beginTime,
            endTime:this.listQuery.endTime,
            bigIds:this.fatherIds,
            userAreaIds:[],
            userIds:this.searchMeters
          }
        }else{//选择区域
          data = {
            beginTime:this.listQuery.beginTime,
            endTime:this.listQuery.endTime,
            bigIds:this.fatherIds,
            userAreaIds:this.chooseAreaIds,
            userIds:[]
          }
        }
        this.loading = true
        basicDataApi.getMterSearch(data).then(response => {
          if(response.code == 200){
            response.columnHeaders.forEach((item) => { // 首字母小写
              item.key = item.key.substring(0, 1).toLowerCase() + item.key.substring(1)
            })
            this.tableHeaderList = response.columnHeaders.filter(u => u.browsable && u.isList)
            this.totalMemtertableData = response.data.bigData
            this.totalChildMemtertableData = response.data.userData
            this.totalFatherNum = response.data.bigDosage //总表流量合计
            this.differenceNum = response.data.leakageDosage  //流量差值
            this.totalChildNum = response.data.userDosage //分表流量合计
            
          }else{
            this.tableHeaderList = []
            this.totalMemtertableData = []
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
          this.loading = false;
        }).catch(()=>{
          this.tableHeaderList = []
          this.totalMemtertableData = []
          this.loading = false;
        })
        
      },
      // 搜索
      handleFilter() {
        this.listQuery.page = 1
        this.getList()
      },
      // 切换分页
      handleCurrentChange(val) {
        this.tableChooseRow = {}//重置选中项
        this.listQuery.page = val.page
        this.listQuery.limit = val.limit
        this.getList()
      },
      // 行点击
      tableRowClick(row){
        this.tableChooseRow = row
      },
      
    },
  }

</script>

<style lang="scss" scoped>
  .zzb{
    display: flex;
    flex-direction: column;
  }
  .line{
    height: 20px;
    width: 100%;
    background: #ccc;
  }
  .Dtop_l_num{
    text-align: center;
  }
  .topNum_title{
    font-size: 12px;
    color: #787a7d;
    margin: 0 0 10px 0;
  }
</style>
